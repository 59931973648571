import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import Newsletter from '@bitcoin-portal/newsletter';
import {
  Code,
  H2,
  Paragraph,
  H3,
  theme,
} from '@bitcoin-portal/bitcoincom-storybook';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  Wrapper,
  Group,
  Widget,
  Content,
  Step,
  CustomContentBlock,
} from './styled';
import SEO from './SEO';
import Header from './Header';
import loadScript from '../../helpers/loadScript';

const Widgets = ({ locale, intl: { messages } }) => {
  useEffect(() => {
    loadScript('https://widgets.bitcoin.com/widget.js');
  }, []);

  return (
    <React.Fragment>
      <SEO />
      <Wrapper>
        <Nav />
        <Header />
        <CustomContentBlock>
          <Content>
            <H2>
              <FormattedMessage id="widgets.title" />
            </H2>
            <Paragraph style={{ marginBottom: `${theme.spacing.unit * 4}px` }}>
              <FormattedMessage id="widgets.description" />
            </Paragraph>
            {messages['widgets.sections'].map(({ title, widgets }) => (
              <React.Fragment key={title}>
                <H2>{title}</H2>
                <Group>
                  {widgets.map((w, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Widget key={i}>
                      <Code language="html">{w}</Code>
                      {/* eslint-disable-next-line */}
                      <div dangerouslySetInnerHTML={{ __html: w }} />
                    </Widget>
                  ))}
                </Group>
              </React.Fragment>
            ))}
            <Step>
              <div>
                <H2>
                  <FormattedMessage id="widgets.usage.step1.title" />
                </H2>
                <Paragraph>
                  <FormattedHTMLMessage id="widgets.usage.step1.description" />
                </Paragraph>
                <H3>
                  <FormattedMessage id="widgets.usage.step1.customize.title" />
                </H3>
                <Paragraph>
                  <FormattedHTMLMessage id="widgets.usage.step1.customize.description" />
                </Paragraph>
              </div>
              <Code language="html">
                {messages['widgets.usage.step1.code']}
              </Code>
            </Step>
            <Step>
              <div>
                <H2>
                  <FormattedMessage id="widgets.usage.step2.title" />
                </H2>
                <Paragraph>
                  <FormattedHTMLMessage id="widgets.usage.step2.description" />
                </Paragraph>
              </div>
              <Code language="html">
                {messages['widgets.usage.step2.code']}
              </Code>
            </Step>
          </Content>
        </CustomContentBlock>
        <Newsletter
          showLogo
          fullWidth
          title={messages['widgets.newsletter.title']}
          subtitle={messages['widgets.newsletter.subtitle']}
          acList={messages['widgets.newsletter.acList']}
        />
        <Footer />
      </Wrapper>
    </React.Fragment>
  );
};

Widgets.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(Widgets);
