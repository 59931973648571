import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  H1,
  Paragraph,
  ContentBlock,
} from '@bitcoin-portal/bitcoincom-storybook';

const Header = () => (
  <ContentBlock hero>
    <H1>
      <FormattedMessage id="widgets.header.title" />
    </H1>
    <Paragraph>
      <FormattedMessage id="widgets.header.description" />
    </Paragraph>
  </ContentBlock>
);

export default Header;
