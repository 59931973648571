import styled from 'styled-components';
import {
  theme,
  media,
  ContentBlock,
} from '@bitcoin-portal/bitcoincom-storybook';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const Content = styled.div`
  text-align: left;
  width: 100%;

  & pre {
    font-size: 0.8rem;
  }
`;

export const Group = styled.div`
  width: 100%;
  padding-bottom: ${theme.spacing.unit * 8}px;

  ${media.md`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -${theme.spacing.unit * 2}px;
  `};
`;

export const Widget = styled.div`
  width: 100%;

  ${media.md`
    width: 50%;
    box-sizing: border-box;
    padding: ${theme.spacing.unit * 2}px;
  `};
`;

export const Step = styled.div`
  padding-bottom: ${theme.spacing.unit * 8}px;
  width: 100%;

  ${media.md`
    display: flex;
    margin: -${theme.spacing.unit * 2}px;

    & > div {
      width: 50%;
      box-sizing: border-box;
      padding: ${theme.spacing.unit * 2}px;
    }
  `};
`;

export const CustomContentBlock = styled(ContentBlock)`
  padding-top: 0;

  & > div > div {
    padding-top: 0;
  }
`;
